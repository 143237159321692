.text {
  text-transform: none !important;
}

.hr {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px !important;
}
.pad-0 {
  padding: 0px !important;
}
.mr-top-25 {
  margin-top: 15px;
}

::ng-deep .MuiTypography-h5 {
  font-size: 2rem;
  font-family: Arial, Muli, Roboto, "Helvetica";
  font-weight: 200 !important;
  line-height: 1.6;
}
.p-15 {
  padding: 12px;
}
.mt-0 {
  margin-top: 0px;
}
.mt-7 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}
.text-14 {
  font-size: 14px;
}
.border-grey {
  border: 1px solid #e0e0e0;
}
.p-40 {
  padding: 40px;
}

.font-13 {
  font-size: 13px;
}

.textfield-border-right fieldset {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.textfield-border-right {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
