@import "normalize.css";
@import "../../node_modules/text-security/dist/text-security.css";

* {
  outline: none !important;
}

html {
  font-size: 62.5%;
  /* font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif; */
  font-family: Arial;
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

.text {
  text-transform: none !important;
}

a[role="button"] {
  text-decoration: none;
}

[role="tooltip"] {
  z-index: 999;
}

.input-hidden {
  font-family: "text-security-disc";
  -webkit-text-security: disc;
}

.ck-editor__editable_inline {
  min-height: 140px !important;
}

.PhoneInput {
  border: 1px solid #c8c8c8;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fafafa;
}

.PhoneInputCountry {
  padding: 0 10px;
  margin: 0;
  background-color: #fafafa;
}

.PhoneInputInput {
  height: 56px;
  padding: 0 14px;
  border-left: 1px solid #c8c8c8;
  background-color: #fafafa;
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
}

.menu-list-item {
  position: relative;
  overflow: hidden;
}

span.menu-list-item-beta {
  background-image: linear-gradient(to right, #2466e6, #012f57);
  text-align: center;
  font-size: 11px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 15px;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
}

.MuiTab-textColorInherit.Mui-disabled {
  opacity: 0.2;
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}

.dmarc-count-text {
  text-align: center;
  font-size: 36px;
  line-height: 55px;
}
.dmarc-count-text.x2 {
  line-height: 110px;
}
/*  */
/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}

@media only screen and (min-width: 499px) and (max-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

.custom-label label {
  font-size: 10px !important;
  transform: translate(14px, 15px) scale(1) !important;
}

@import "print.css";

@import "tables.css";

@import "react-table.css";

@import "prism.css";

@import "tailwind.css";
