/*@tailwind preflight;*/

.bg-transparent {
  background-color: transparent
}

.bg-black {
  background-color: #22292F
}

.bg-white {
  background-color: #FFF
}

.bg-gray-50 {
  background-color: #FAFAFA
}

.bg-gray-100 {
  background-color: #F5F5F5
}

.bg-gray-200 {
  background-color: #EEEEEE
}

.bg-gray-300 {
  background-color: #E0E0E0
}

.bg-gray-500 {
  background-color: #9E9E9E
}

.bg-gray-600 {
  background-color: #757575
}

.bg-gray-700 {
  background-color: #616161
}

.bg-gray {
  background-color: #9E9E9E
}

.bg-red-50 {
  background-color: #FFEBEE
}

.bg-red-100 {
  background-color: #FFCDD2
}

.bg-red-300 {
  background-color: #E57373
}

.bg-red-400 {
  background-color: #EF5350
}

.bg-red-500 {
  background-color: #F44336
}

.bg-red-600 {
  background-color: #E53935
}

.bg-red-700 {
  background-color: #D32F2F
}

.bg-red-800 {
  background-color: #C62828
}

.bg-red-900 {
  background-color: #B71C1C
}

.bg-red {
  background-color: #F44336
}

.bg-orange-300 {
  background-color: #FFB74D
}

.bg-orange-400 {
  background-color: #FFA726
}

.bg-orange-500 {
  background-color: #FF9800
}

.bg-orange-600 {
  background-color: #FB8C00
}

.bg-orange-800 {
  background-color: #EF6C00
}

.bg-orange-900 {
  background-color: #E65100
}

.bg-yellow-100 {
  background-color: #FFF9C4
}

.bg-yellow-500 {
  background-color: #FFEB3B
}

.bg-yellow-600 {
  background-color: #FDD835
}

.bg-yellow-700 {
  background-color: #FBC02D
}

.bg-yellow-800 {
  background-color: #F9A825
}

.bg-yellow-900 {
  background-color: #F57F17
}

.bg-green-50 {
  background-color: #E8F5E9
}

.bg-green-100 {
  background-color: #C8E6C9
}

.bg-green-300 {
  background-color: #81C784
}

.bg-green-400 {
  background-color: #66BB6A
}

.bg-green-500 {
  background-color: #4CAF50
}

.bg-green-600 {
  background-color: #43A047
}

.bg-green-700 {
  background-color: #388E3C
}

.bg-green-800 {
  background-color: #2E7D32
}

.bg-green-900 {
  background-color: #1B5E20
}

.bg-green {
  background-color: #4CAF50
}

.bg-teal-400 {
  background-color: #26A69A
}

.bg-blue-100 {
  background-color: #BBDEFB
}

.bg-blue-300 {
  background-color: #64B5F6
}

.bg-blue-400 {
  background-color: #42A5F5
}

.bg-blue-500 {
  background-color: #2196F3
}

.bg-blue-600 {
  background-color: #1E88E5
}

.bg-blue-700 {
  background-color: #1976D2
}

.bg-blue-800 {
  background-color: #1565C0
}

.bg-blue-900 {
  background-color: #0D47A1
}

.bg-indigo-300 {
  background-color: #7986CB
}

.bg-indigo-400 {
  background-color: #5C6BC0
}

.bg-indigo-900 {
  background-color: #1A237E
}

.bg-purple-200 {
  background-color: #CE93D8
}

.bg-purple-300 {
  background-color: #BA68C8
}

.bg-purple-400 {
  background-color: #AB47BC
}

.bg-purple-500 {
  background-color: #9C27B0
}

.bg-purple-700 {
  background-color: #7B1FA2
}

.bg-purple-800 {
  background-color: #6A1B9A
}

.bg-purple-900 {
  background-color: #4A148C
}

.bg-pink-300 {
  background-color: #F06292
}

.bg-pink-400 {
  background-color: #EC407A
}

.bg-pink-600 {
  background-color: #D81B60
}

.bg-grey-100 {
  background-color: #F5F5F5
}

.bg-grey-200 {
  background-color: #EEEEEE
}

.bg-grey-300 {
  background-color: #E0E0E0
}

.bg-grey-500 {
  background-color: #9E9E9E
}

.bg-grey-600 {
  background-color: #757575
}

.bg-grey-900 {
  background-color: #212121
}

.bg-grey {
  background-color: #9E9E9E
}

.bg-lime-300 {
  background-color: #DCE775
}

.bg-lime-600 {
  background-color: #C0CA33
}

.bg-cyan-300 {
  background-color: #4DD0E1
}

.bg-cyan-400 {
  background-color: #26C6DA
}

.bg-cyan-900 {
  background-color: #006064
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-gray-400:hover {
  background-color: #BDBDBD
}

.hover\:bg-gray-900:hover {
  background-color: #212121
}

.hover\:bg-red-500:hover {
  background-color: #F44336
}

.hover\:bg-red-600:hover {
  background-color: #E53935
}

.hover\:bg-red-700:hover {
  background-color: #D32F2F
}

.hover\:bg-orange-600:hover {
  background-color: #FB8C00
}

.hover\:bg-orange-800:hover {
  background-color: #EF6C00
}

.hover\:bg-green-600:hover {
  background-color: #43A047
}

.hover\:bg-blue-600:hover {
  background-color: #1E88E5
}

.hover\:bg-grey-200:hover {
  background-color: #EEEEEE
}

.hover\:bg-grey-300:hover {
  background-color: #E0E0E0
}

.hover\:bg-grey-600:hover {
  background-color: #757575
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.border-gray-300 {
  border-color: #E0E0E0
}

.border-gray-400 {
  border-color: #BDBDBD
}

.border-gray-500 {
  border-color: #9E9E9E
}

.border-gray-600 {
  border-color: #757575
}

.border-red-400 {
  border-color: #EF5350
}

.border-red-500 {
  border-color: #F44336
}

.border-orange-500 {
  border-color: #FF9800
}

.border-yellow-700 {
  border-color: #FBC02D
}

.border-green-600 {
  border-color: #43A047
}

.border-blue-200 {
  border-color: #90CAF9
}

.border-blue-600 {
  border-color: #1E88E5
}

.border-blue-A500 {
  border-color: #32B1F5
}

.border-grey-300 {
  border-color: #E0E0E0
}

.border-grey-500 {
  border-color: #9E9E9E
}

.border-grey {
  border-color: #9E9E9E
}

.hover\:border-orange-600:hover {
  border-color: #FB8C00
}

.hover\:border-grey-600:hover {
  border-color: #757575
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-12 {
  border-radius: 1.2rem
}

.rounded-none {
  border-radius: 0
}

.rounded-sm {
  border-radius: .2rem
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-t-8 {
  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem
}

.rounded-t {
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem
}

.rounded-r {
  border-top-right-radius: .4rem;
  border-bottom-right-radius: .4rem
}

.rounded-b {
  border-bottom-right-radius: .4rem;
  border-bottom-left-radius: .4rem
}

.border-solid {
  border-style: solid
}

.border-0 {
  border-width: 0
}

.border-1 {
  border-width: 1px
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-b-0 {
  border-bottom-width: 0
}

.border-t-1 {
  border-top-width: 1px
}

.border-r-1 {
  border-right-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-t-2 {
  border-top-width: 2px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-l {
  border-left-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-none {
  flex: none
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.float-left {
  float: left
}

.font-500 {
  font-weight: 500
}

.font-600 {
  font-weight: 600
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-bold {
  font-weight: 700
}

.h-2 {
  height: 0.5rem
}

.h-3 {
  height: 0.75rem
}

.h-8 {
  height: 0.8rem
}

.h-10 {
  height: 2.5rem
}

.h-16 {
  height: 1.6rem
}

.h-28 {
  height: 2.8rem
}

.h-36 {
  height: 3.6rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-52 {
  height: 5.2rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-400 {
  height: 40rem
}

.h-640 {
  height: 64rem
}

.h-auto {
  height: auto
}

.h-px {
  height: 1px
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.leading-none {
  line-height: 1
}

.leading-tight {
  line-height: 1.25
}

.m-0 {
  margin: 0
}

.m-1 {
  margin: 0.25rem
}

.m-2 {
  margin: 0.5rem
}

.m-5 {
  margin: 1.25rem
}

.m-8 {
  margin: 0.8rem
}

.m-10 {
  margin: 2.5rem
}

.m-12 {
  margin: 1.2rem
}

.m-16 {
  margin: 1.6rem
}

.m-20 {
  margin: 2rem
}

.m-32 {
  margin: 3.2rem
}

.m-auto {
  margin: auto
}

.-m-2 {
  margin: -0.5rem
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-20 {
  margin-left: 2rem;
  margin-right: 2rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem
}

.mx-32 {
  margin-left: 3.2rem;
  margin-right: 3.2rem
}

.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem
}

.my-60 {
  margin-top: 6rem;
  margin-bottom: 6rem
}

.my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem
}

.mx-160 {
  margin-left: 16rem;
  margin-right: 16rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.-mx-20 {
  margin-left: -2rem;
  margin-right: -2rem
}

.-mx-24 {
  margin-left: -2.4rem;
  margin-right: -2.4rem
}

.mt-0 {
  margin-top: 0
}

.mr-0 {
  margin-right: 0
}

.ml-0 {
  margin-left: 0
}

.mt-1 {
  margin-top: 0.25rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.ml-3 {
  margin-left: 0.75rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.ml-5 {
  margin-left: 1.25rem
}

.mt-6 {
  margin-top: 0.6rem
}

.mb-6 {
  margin-bottom: 0.6rem
}

.ml-6 {
  margin-left: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mr-10 {
  margin-right: 2.5rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.ml-10 {
  margin-left: 2.5rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mr-12 {
  margin-right: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mr-20 {
  margin-right: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.mb-28 {
  margin-bottom: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mr-32 {
  margin-right: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.ml-32 {
  margin-left: 3.2rem
}

.mt-36 {
  margin-top: 3.6rem
}

.mr-36 {
  margin-right: 3.6rem
}

.mb-36 {
  margin-bottom: 3.6rem
}

.mr-40 {
  margin-right: 4rem
}

.mb-40 {
  margin-bottom: 4rem
}

.mt-44 {
  margin-top: 4.4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.mt-56 {
  margin-top: 5.6rem
}

.mb-56 {
  margin-bottom: 5.6rem
}

.mt-60 {
  margin-top: 6rem
}

.mr-60 {
  margin-right: 6rem
}

.mt-64 {
  margin-top: 6.4rem
}

.mb-64 {
  margin-bottom: 6.4rem
}

.-mt-1 {
  margin-top: -0.25rem
}

.-ml-1 {
  margin-left: -0.25rem
}

.-mt-2 {
  margin-top: -0.5rem
}

.-ml-2 {
  margin-left: -0.5rem
}

.-mt-4 {
  margin-top: -0.4rem
}

.-mt-5 {
  margin-top: -1.25rem
}

.-mr-5 {
  margin-right: -1.25rem
}

.-mb-5 {
  margin-bottom: -1.25rem
}

.-ml-5 {
  margin-left: -1.25rem
}

.-mt-10 {
  margin-top: -2.5rem
}

.-mr-10 {
  margin-right: -2.5rem
}

.-ml-10 {
  margin-left: -2.5rem
}

.-mt-20 {
  margin-top: -2rem
}

.-mb-20 {
  margin-bottom: -2rem
}

.-ml-28 {
  margin-left: -2.8rem
}

.-mt-32 {
  margin-top: -3.2rem
}

.-mt-40 {
  margin-top: -4rem
}

.-mb-48 {
  margin-bottom: -4.8rem
}

.-mt-64 {
  margin-top: -6.4rem
}

.max-w-20 {
  max-width: 2rem
}

.max-w-128 {
  max-width: 12.8rem
}

.max-w-256 {
  max-width: 25.6rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-lg {
  max-width: 80rem
}

.max-w-full {
  max-width: 100%
}

.min-h-0 {
  min-height: 0
}

.min-h-64 {
  min-height: 6.4rem
}

.min-w-0 {
  min-width: 0
}

.min-w-28 {
  min-width: 2.8rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-full {
  min-width: 100%
}

.min-w-xl {
  min-width: 96rem
}

.opacity-25 {
  opacity: 0.25
}

.opacity-50 {
  opacity: 0.5
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 0.4rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 0.6rem
}

.p-8 {
  padding: 0.8rem
}

.p-10 {
  padding: 2.5rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-20 {
  padding: 2rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.p-36 {
  padding: 3.6rem
}

.p-48 {
  padding: 4.8rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.px-28 {
  padding-left: 2.8rem;
  padding-right: 2.8rem
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.py-36 {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem
}

.px-36 {
  padding-left: 3.6rem;
  padding-right: 3.6rem
}

.py-40 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.px-40 {
  padding-left: 4rem;
  padding-right: 4rem
}

.py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem
}

.px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem
}

.px-52 {
  padding-left: 5.2rem;
  padding-right: 5.2rem
}

.px-60 {
  padding-left: 6rem;
  padding-right: 6rem
}

.py-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem
}

.px-64 {
  padding-left: 6.4rem;
  padding-right: 6.4rem
}

.pt-0 {
  padding-top: 0
}

.pr-0 {
  padding-right: 0
}

.pl-0 {
  padding-left: 0
}

.pt-1 {
  padding-top: 0.25rem
}

.pr-1 {
  padding-right: 0.25rem
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pl-1 {
  padding-left: 0.25rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pr-4 {
  padding-right: 0.4rem
}

.pl-4 {
  padding-left: 0.4rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-5 {
  padding-right: 1.25rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pt-6 {
  padding-top: 0.6rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pl-10 {
  padding-left: 2.5rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pr-20 {
  padding-right: 2rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pb-28 {
  padding-bottom: 2.8rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pl-40 {
  padding-left: 4rem
}

.pb-48 {
  padding-bottom: 4.8rem
}

.pl-48 {
  padding-left: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pt-64 {
  padding-top: 6.4rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.pt-128 {
  padding-top: 12.8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.resize {
  resize: both
}

.shadow {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10)
}

.shadow-md {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)
}

.shadow-lg {
  box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)
}

.shadow-none {
  box-shadow: none
}

.fill-current {
  fill: currentColor
}

.table-auto {
  table-layout: auto
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-black {
  color: #22292F
}

.text-white {
  color: #FFF
}

.text-gray-300 {
  color: #E0E0E0
}

.text-gray-400 {
  color: #BDBDBD
}

.text-gray-500 {
  color: #9E9E9E
}

.text-gray-600 {
  color: #757575
}

.text-gray-700 {
  color: #616161
}

.text-gray-800 {
  color: #424242
}

.text-gray-900 {
  color: #212121
}

.text-red-400 {
  color: #EF5350
}

.text-red-500 {
  color: #F44336
}

.text-red-600 {
  color: #E53935
}

.text-red-700 {
  color: #D32F2F
}

.text-red-900 {
  color: #B71C1C
}

.text-red {
  color: #F44336
}

.text-orange-400 {
  color: #FFA726
}

.text-orange-500 {
  color: #FF9800
}

.text-orange {
  color: #FF9800
}

.text-yellow-600 {
  color: #FDD835
}

.text-yellow-700 {
  color: #FBC02D
}

.text-yellow-900 {
  color: #F57F17
}

.text-yellow {
  color: #FFEB3B
}

.text-green-400 {
  color: #66BB6A
}

.text-green-500 {
  color: #4CAF50
}

.text-green-600 {
  color: #43A047
}

.text-green-700 {
  color: #388E3C
}

.text-green-800 {
  color: #2E7D32
}

.text-green-900 {
  color: #1B5E20
}

.text-green {
  color: #4CAF50
}

.text-blue-500 {
  color: #2196F3
}

.text-blue-600 {
  color: #1E88E5
}

.text-blue-700 {
  color: #1976D2
}

.text-blue-900 {
  color: #0D47A1
}

.text-blue {
  color: #2196F3
}

.text-indigo-400 {
  color: #5C6BC0
}

.text-purple-500 {
  color: #9C27B0
}

.text-pink-600 {
  color: #D81B60
}

.text-grey-400 {
  color: #BDBDBD
}

.text-grey-500 {
  color: #9E9E9E
}

.text-grey-600 {
  color: #757575
}

.text-grey-700 {
  color: #616161
}

.text-grey-800 {
  color: #424242
}

.text-grey-900 {
  color: #212121
}

.hover\:text-black:hover {
  color: #22292F
}

.hover\:text-white:hover {
  color: #FFF
}

.hover\:text-grey:hover {
  color: #9E9E9E
}

.focus\:text-white:focus {
  color: #FFF
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-60 {
  font-size: 6rem
}

.text-68 {
  font-size: 6.8rem
}

.text-72 {
  font-size: 7.2rem
}

.text-96 {
  font-size: 9.6rem
}

.text-xs {
  font-size: 1.2rem
}

.text-sm {
  font-size: 2.4rem
}

.text-base {
  font-size: 1.6rem
}

.text-xl {
  font-size: 2rem
}

.text-3xl {
  font-size: 3rem
}

.text-6xl {
  font-size: 4rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.no-underline {
  text-decoration: none
}

.hover\:underline:hover {
  text-decoration: underline
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.align-middle {
  vertical-align: middle
}

.visible {
  visibility: visible
}

.whitespace-normal {
  white-space: normal
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-words {
  overflow-wrap: break-word
}

.break-all {
  word-break: break-all
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-1 {
  width: 0.25rem
}

.w-4 {
  width: 0.4rem
}

.w-10 {
  width: 2.5rem
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-28 {
  width: 2.8rem
}

.w-32 {
  width: 3.2rem
}

.w-36 {
  width: 3.6rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-52 {
  width: 5.2rem
}

.w-64 {
  width: 6.4rem
}

.w-160 {
  width: 16rem
}

.w-200 {
  width: 20rem
}

.w-256 {
  width: 25.6rem
}

.w-320 {
  width: 32rem
}

.w-360 {
  width: 36rem
}

.w-384 {
  width: 38.4rem
}

.w-xs {
  width: 32rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.w-4\/5 {
  width: 80%
}

.w-1\/6 {
  width: 16.666667%
}

.w-2\/6 {
  width: 33.333333%
}

.w-4\/6 {
  width: 66.666667%
}

.w-1\/12 {
  width: 8.333333%
}

.w-2\/12 {
  width: 16.666667%
}

.w-3\/12 {
  width: 25%
}

.w-4\/12 {
  width: 33.333333%
}

.w-5\/12 {
  width: 41.666667%
}

.w-6\/12 {
  width: 50%
}

.w-7\/12 {
  width: 58.333333%
}

.w-8\/12 {
  width: 66.666667%
}

.w-9\/12 {
  width: 75%
}

.w-11\/12 {
  width: 91.666667%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-50 {
  z-index: 50
}

.z-9999 {
  z-index: 9999
}

@media (min-width: 600px) {

  .sm\:block {
    display: block
  }

  .sm\:flex {
    display: flex
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-wrap {
    flex-wrap: wrap
  }

  .sm\:items-start {
    align-items: flex-start
  }

  .sm\:justify-start {
    justify-content: flex-start
  }

  .sm\:justify-end {
    justify-content: flex-end
  }

  .sm\:justify-between {
    justify-content: space-between
  }

  .sm\:h-56 {
    height: 5.6rem
  }

  .sm\:m-0 {
    margin: 0
  }

  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .sm\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .sm\:-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem
  }

  .sm\:mt-0 {
    margin-top: 0
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:ml-2 {
    margin-left: 0.5rem
  }

  .sm\:ml-5 {
    margin-left: 1.25rem
  }

  .sm\:ml-10 {
    margin-left: 2.5rem
  }

  .sm\:mr-32 {
    margin-right: 3.2rem
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem
  }

  .sm\:-ml-16 {
    margin-left: -1.6rem
  }

  .sm\:-mt-48 {
    margin-top: -4.8rem
  }

  .sm\:overflow-x-auto {
    overflow-x: auto
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:p-48 {
    padding: 4.8rem
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .sm\:px-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .sm\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .sm\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  .sm\:px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem
  }

  .sm\:break-normal {
    overflow-wrap: normal;
    word-break: normal
  }

  .sm\:break-words {
    overflow-wrap: break-word
  }

  .sm\:w-256 {
    width: 25.6rem
  }

  .sm\:w-320 {
    width: 32rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }

  .sm\:w-2\/3 {
    width: 66.666667%
  }

  .sm\:w-1\/4 {
    width: 25%
  }

  .sm\:w-2\/4 {
    width: 50%
  }

  .sm\:w-3\/4 {
    width: 75%
  }

  .sm\:w-1\/5 {
    width: 20%
  }

  .sm\:w-2\/5 {
    width: 40%
  }

  .sm\:w-3\/5 {
    width: 60%
  }

  .sm\:w-4\/5 {
    width: 80%
  }

  .sm\:w-1\/6 {
    width: 16.666667%
  }

  .sm\:w-2\/6 {
    width: 33.333333%
  }

  .sm\:w-4\/6 {
    width: 66.666667%
  }

  .sm\:w-5\/6 {
    width: 83.333333%
  }

  .sm\:w-1\/12 {
    width: 8.333333%
  }

  .sm\:w-2\/12 {
    width: 16.666667%
  }

  .sm\:w-3\/12 {
    width: 25%
  }

  .sm\:w-4\/12 {
    width: 33.333333%
  }

  .sm\:w-5\/12 {
    width: 41.666667%
  }

  .sm\:w-6\/12 {
    width: 50%
  }

  .sm\:w-8\/12 {
    width: 66.666667%
  }

  .sm\:w-9\/12 {
    width: 75%
  }

  .sm\:w-10\/12 {
    width: 83.333333%
  }

  .sm\:w-11\/12 {
    width: 91.666667%
  }

  .sm\:w-full {
    width: 100%
  }
}

@media (min-width: 960px) {

  .md\:block {
    display: block
  }

  .md\:inline-block {
    display: inline-block
  }

  .md\:flex {
    display: flex
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:justify-end {
    justify-content: flex-end
  }

  .md\:justify-between {
    justify-content: space-between
  }

  .md\:flex-1 {
    flex: 1 1 0%
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:h-full {
    height: 100%
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mr-0 {
    margin-right: 0
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:mr-2 {
    margin-right: 0.5rem
  }

  .md\:mr-5 {
    margin-right: 1.25rem
  }

  .md\:ml-5 {
    margin-left: 1.25rem
  }

  .md\:mr-10 {
    margin-right: 2.5rem
  }

  .md\:ml-32 {
    margin-left: 3.2rem
  }

  .md\:-mr-5 {
    margin-right: -1.25rem
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-12 {
    padding: 1.2rem
  }

  .md\:p-48 {
    padding: 4.8rem
  }

  .md\:p-128 {
    padding: 12.8rem
  }

  .md\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .md\:px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem
  }

  .md\:pr-5 {
    padding-right: 1.25rem
  }

  .md\:pr-10 {
    padding-right: 2.5rem
  }

  .md\:pr-12 {
    padding-right: 1.2rem
  }

  .md\:pr-24 {
    padding-right: 2.4rem
  }

  .md\:pl-32 {
    padding-left: 3.2rem
  }

  .md\:pt-128 {
    padding-top: 12.8rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-lg {
    font-size: 1.8rem
  }

  .md\:text-2xl {
    font-size: 2.4rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-2\/3 {
    width: 66.666667%
  }

  .md\:w-1\/4 {
    width: 25%
  }

  .md\:w-2\/4 {
    width: 50%
  }

  .md\:w-3\/4 {
    width: 75%
  }

  .md\:w-1\/5 {
    width: 20%
  }

  .md\:w-2\/5 {
    width: 40%
  }

  .md\:w-3\/5 {
    width: 60%
  }

  .md\:w-4\/5 {
    width: 80%
  }

  .md\:w-5\/6 {
    width: 83.333333%
  }

  .md\:w-1\/12 {
    width: 8.333333%
  }

  .md\:w-2\/12 {
    width: 16.666667%
  }

  .md\:w-3\/12 {
    width: 25%
  }

  .md\:w-4\/12 {
    width: 33.333333%
  }

  .md\:w-5\/12 {
    width: 41.666667%
  }

  .md\:w-6\/12 {
    width: 50%
  }

  .md\:w-8\/12 {
    width: 66.666667%
  }

  .md\:w-9\/12 {
    width: 75%
  }

  .md\:w-10\/12 {
    width: 83.333333%
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:block {
    display: block
  }

  .lg\:flex {
    display: flex
  }

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:max-w-200 {
    max-width: 20rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:w-1\/4 {
    width: 25%
  }

  .lg\:w-3\/4 {
    width: 75%
  }

  .lg\:w-1\/5 {
    width: 20%
  }

  .lg\:w-4\/5 {
    width: 80%
  }

  .lg\:w-6\/12 {
    width: 50%
  }

  .lg\:w-8\/12 {
    width: 66.666667%
  }

  .lg\:w-full {
    width: 100%
  }

  .lg\:w-1\/8 {
    width: 12.5%
  }
}

@media (min-width: 1920px) {

  .xl\:mr-5 {
    margin-right: 1.25rem
  }

  .xl\:ml-5 {
    margin-left: 1.25rem
  }

  .xl\:absolute {
    position: absolute
  }

  .xl\:right-0 {
    right: 0
  }

  .xl\:left-0 {
    left: 0
  }

  .xl\:w-1\/6 {
    width: 16.666667%
  }

  .xl\:w-5\/6 {
    width: 83.333333%
  }
}
